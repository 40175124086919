<template>
    <div class="card">
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
            <div class="m-2">
                <b-row class="mb-1">
                    <b-col cols="12" md="6"            
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h4 class="table_title" style="padding:0px;">Announcement</h4>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <v-select
                            v-model="perPage"
                            style="width: 90px"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>
                </b-row>
            </div>
            <b-table
                striped
                hover
                responsive
                show-empty
                v-model="applicationTable"
                empty-text="No matching records found"
                :per-page="perPage"
                :current-page="currentPage"
                :items="announcementList"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="position-relative promotionalNotification"
            >
                <template #cell(id)="data">
                    {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                </template>
                <template #cell(title)="data">
                    <div>
                        {{data.item.title}}
                    </div>
                </template>

                <template #cell(discription)="data">
                    {{data.item.discription}}
                </template>

                <template #cell(createtime)="data">
                    {{data.item.createtime}}
                </template>


                <template #cell(status)="data">
                    <b-dropdown
                        :disabled="onlyRead"
                        :text="data.item.status"
                    >
                    <b-dropdown-item :disabled="data.item.status == 'Inactive'" @click="selectedStatus = 0,selectedId = data.item.id,$bvModal.show('modal-change-status-isActive')">Inactive</b-dropdown-item>
                    <b-dropdown-item :disabled="data.item.status == 'Active'" @click="selectedStatus1 = 1,selectedId1 = data.item.id,changeStatusModelActive()">Active</b-dropdown-item>
                    <!-- <b-dropdown-item @click="selectedStatus = 0,selectedId = data.item.id,$bvModal.show('modal-change-status-isActive')">Inactive</b-dropdown-item>
                    <b-dropdown-item @click="selectedStatus1 = 1,selectedId1 = data.item.id,changeStatusModelActive()">Active</b-dropdown-item> -->
                    </b-dropdown>
                </template>
                <template #cell(action)="data">
                    <a><img src='../../../assets/images/erflog/Delete.png' :class="{'pointerEventNull': onlyRead}" v-if="data.item.status == 'Inactive'" class="action_icon" @click.prevent="deleteItem = data.item.id,$bvModal.show('modal-delete-annaouncement')"/></a>
                </template>

                <!-- <template #cell(action)="data">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="resendNotification(data.item)"
                    >
                        Resend
                    </b-button>
                </template> -->
            </b-table>
            <div class="mx-2 mb-2">
                <div class="row">
                    <b-col md="6" class="d-flex align-items-center">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ perPage }} of {{ announcementList.length }} entries</span>
                        <!-- <span class="text-muted mt-1">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span> -->
                    </b-col>
                    <b-col  md="6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="announcementList.length"
                            :per-page="perPage"
                            align="right"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0 custom_pagination"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </div>
            </div>
            
        </div>
        <b-modal
            id="modal-delete-annaouncement"
            cancel-variant="outline-secondary"
            ok-title="Delete"
            ok-variant="danger"
            cancel-title="Cancel"
            centered
            title=""
            :no-close-on-backdrop="true"
            @ok="deleteAnnouncementFunction"
            @cancel="cancelDeleteModel"
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/DeletePopup.png" alt="">
        </div>
        <div style="text-align: center;" v-if="deleteItem!=''">
            <!-- <label class="s_modal_title">Announcement Delete</label> -->
            <p>Are you sure you want to delete this announcement ?</p>
        </div>
        </b-modal>
        <b-modal
            id="modal-change-status-isActive"
            cancel-variant="outline-secondary"
            ok-title="Yes"
            ok-variant="success"
            cancel-title="No"
            centered
            title=""
            :no-close-on-backdrop="true"
            @ok="changeStatusModel"
            @cancel="cancelChangeModel"
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/Approve_Popup.png" alt="">
        </div>
        <div style="text-align: center;">
            <!-- <label class="s_modal_title">Status Change</label> -->
            <p>Are you sure you want to change status to Inactive ?</p>
        </div>
        </b-modal>
        <b-modal
            id="modal-for-send-noti"
            cancel-variant="outline-secondary"
            ok-title="Yes"
            ok-variant="success"
            cancel-title="No"
            centered
            title=""
            :no-close-on-backdrop="true"
            @ok="sendNotification"
            @cancel="cancelNotiModel"
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/Approve_Popup.png" alt="">
        </div>
        <div style="text-align: center;">
            <!-- <label class="s_modal_title">Status Change</label> -->
            <p>Are you sure you want to send notification ?</p>
        </div>
        </b-modal>
    </div>
</template>

<script>
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BSpinner,
  BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg, BDropdownItem,BDropdown
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import firebaseTimeStamp from "firebase";
import moment from 'moment';
export default {
   components: {
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BSpinner,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        vSelect,
        Ripple,
        BDropdownItem,
        BDropdown
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50],
            applicationTable:[],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            isSpinner:false,
            filterOn: [],
            fields: [
                {
                    key: 'title', label: 'Title',sortable: true
                },
                {
                    key: 'discription', label: 'Discription',sortable: true
                },
                {
                    key: 'createtime', label: 'Create Time',sortable: true
                },
                {
                    key: 'status', label: 'Status'
                },
                {
                    key: 'action', label: 'Action'
                },
            ],
            statusOptions:[
                {value:0,text:'Inactive'},
                {value:1,text:'Active'}
            ],
            announcementList:[],
            announcementStatus:[],
            selectedStatus:null,
            selectedStatus1:null,
            selectedId:'',
            selectedId1:'',
            deleteItem:'',
            announcementObjectForNotification:null,
            onlyRead:false
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        cancelDeleteModel() {
            this.deleteItem = '';
            this.$bvModal.hide('modal-delete-annaouncement');
        },
        changeStatusModel(){
            try {
                this.isSpinner = true;
                let updateObject = {
                    status:0,
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                }
                db.collection(dbCollections.ANNOUNCEMENT).doc(this.selectedId).update(updateObject).then(()=>{
                    let findIndex = this.announcementList.findIndex((ele)=>{
                        return ele.id == this.selectedId
                    })
                    if(findIndex !== -1) {
                        this.announcementList[findIndex] = {...this.announcementList[findIndex],status:this.selectedStatus == 0? 'Inactive' : 'Active'};
                    }
                    this.$forceUpdate();
                    this.isSpinner = false;
                    this.selectedId = '';
                    this.selectedStatus = null;
                    this.$bvModal.hide('modal-change-status-isActive');
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Announcement status changed successfully.',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    });
                }).catch((errr)=>{
                    this.selectedId = '';
                    this.selectedStatus = null;
                    this.$bvModal.hide('modal-change-status-isActive');
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Some thing went wrong.',
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                    this.isSpinner = false;
                    console.error(errr)
                })
            } catch (error) {
                this.$bvModal.hide('modal-change-status-isActive');
                console.error(error)
            }
            // update quesry
        },
        cancelChangeModel(){
            this.selectedId = '';
            this.selectedStatus = null;
            this.$bvModal.hide('modal-change-status-Inactive')
        },
        changeStatusModelActive(){
            try {
                this.isSpinner = true;
                let updateObject = {
                    status:1,
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                }
                db.collection(dbCollections.ANNOUNCEMENT).doc(this.selectedId1).update(updateObject).then(()=>{
                    let findIndex = this.announcementList.findIndex((ele)=>{
                        return ele.id == this.selectedId1
                    })
                    if(findIndex !== -1) {  
                        this.announcementList[findIndex] = {...this.announcementList[findIndex],status:this.selectedStatus1 == 0? 'Inactive' : 'Active',statusVal:this.selectedStatus1};
                    }
                    this.announcementObjectForNotification = this.announcementList[findIndex];
                    this.$forceUpdate();
                    this.isSpinner = false;
                    this.selectedId1 = '';
                    this.selectedStatus1 = null;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Announcement status changed successfully.',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    });
                    this.$bvModal.show('modal-for-send-noti');
                }).catch((errr)=>{
                    this.selectedId1 = '';
                    this.selectedStatus1 = null;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Some thing went wrong.',
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                    this.isSpinner = false;
                    console.error(errr)
                })
            } catch (error) {
                console.error(error)
            }
            // update quesry
        },
        cancelChangeModel1(){
            this.selectedId1 = '';
            this.selectedStatus1 = null;
            // this.$bvModal.hide('modal-change-status-Active')
        },
        deleteAnnouncementFunction(){
            this.isSpinner = true;
            db.collection(dbCollections.ANNOUNCEMENT).doc(this.deleteItem).delete().then(()=>{
                let findIndex = this.announcementList.findIndex((ele)=>{
                    return ele.id == this.deleteItem
                })
                if(findIndex !== -1) {
                    this.announcementList.splice(findIndex,1);
                }
                this.isSpinner = false;
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Announcement deleted successfully.',
                        icon: 'CoffeeIcon',
                        variant: 'success',                               
                    },
                });
            })
        },
        sendNotification(){
            this.isSpinner = true;
            console.log("NOTIFICATION SEND",this.announcementObjectForNotification);
            let notificationObj = {
                title: 'Announcment',
                description: this.announcementObjectForNotification.discription,
                announcementId: this.announcementObjectForNotification.id,
            }
            this.$axios.post(dbCollections.APIURL+'api/v1/announcement',notificationObj)
            .then((resp)=>{
                if (resp.data.status) {
                    this.isSpinner = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Notification sent successfully .',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    });
                } else {
                    this.isSpinner = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Notification not sent',
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                }
            }).catch((error)=>{
                console.error(error);
                this.isSpinner = false;
            });
        },
        cancelNotiModel(){
            console.log("NOTIFICATION MODEL CLOSE SEND",this.announcementObjectForNotification);
            this.announcementObjectForNotification = null
            this.$bvModal.hide('modal-for-send-noti');
            console.log("NOTIFICATION MODEL CLOSE SEND",this.announcementObjectForNotification);

        }
    },
    computed: {
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.announcementList.length,
            }
        },
        // reciverName(data){
        //     if (data == 'all') {
        //         return 'All User'
        //     }
        //     if (data == 'guest') {
        //         return 'Guest'
        //     }
        //     if (data == 'student') {
        //         return 'All Student'
        //     }
        //     if (data == 'custom') {
        //         return 'Custom'
        //     }
        // },
    },
    async created() {
        try {     
            this.isSpinner = true;
            let userAccess = JSON.parse(localStorage.getItem('userAccess'));
            if(userAccess) {
                console.log(typeof userAccess)
                let index = userAccess.findIndex((ele)=>{
                    return ele.name === "Announcement"
                })
                if(index !== -1) {
                    if(userAccess[index].allow.toLowerCase() == 'read') {
                        console.log("ION CONDITIOn")
                        this.onlyRead = true;
                    }
                }
            }
            await db.collection(dbCollections.SETTING).doc('announcementStatus').get().then((res)=>{
                if(!res.exists) {
                    let object = {
                        'status':{
                            0:'Inactive',
                            1:'Active'
                        }
                    }
                    db.collection(dbCollections.SETTING).doc('announcementStatus').set(object)
                }
            })
            await db.collection(dbCollections.SETTING).doc('announcementStatus').get().then((res)=>{
                this.announcementStatus = res.data().status;
                db.collection(dbCollections.ANNOUNCEMENT).orderBy('createdAt','desc').get().then((resp) => {
                    if (resp.empty) {
                        this.isSpinner = false;
                        console.error(resp);
                        return;
                    }
                    resp.docs.forEach((data)=>{
                        let obj = {
                            title : data.data().title ? data.data().title : '',
                            discription : data.data().discription ? data.data().discription : '',
                            id: data.data().id ? data.data().id : '',
                            status: this.announcementStatus[data.data().status],
                            statusVal: data.data().status,
                            createtime: data.data().createdAt ? moment(data.data().createdAt.seconds * 1000).format("DD-MM-YYYY") : ''
                        }
                        this.announcementList.push(obj);
                        this.isSpinner = false;
                    });
                }).catch((error) => {
                    this.isSpinner = false;
                    console.error(error);
                });
            })
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
        }
    },
}
</script>
<style scoped>
.pointerEventNull {
    pointer-events: none !important;
    cursor: default !important;
}
</style>